import React, { Component } from 'react'
import Star from './StarIcon'
import JumboPanel from './JumboPanel'
import { Alert, Container, Row, Col, InputGroup, FormControl, Button, ListGroup } from 'react-bootstrap'

const loadImage = require('blueimp-load-image')

class Post extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileAdded: false,
      messageText: null,
      uploaded: false,
      emptyMessage: true,
      messageLength: 0,
    }

    this.handleMessageChange = this.handleMessageChange.bind(this)
  }

  handleFileChange() {
    const numFiles = document.getElementById('uploadedImage').files.length

    if (numFiles < 1) {
      this.setState({
        fileAdded: false,
      })
    } else {
      loadImage( document.getElementById('uploadedImage').files[0], img => {
        this.setState({
          fileAdded: true,
        })

        const canvas = this.refs.canvas
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

      }, {
        cover: true,
        maxWidth: 800,
        maxHeight: 800,
        orientation: true,
      })
    }
  }

  handleMessageChange(e) {
    this.setState({
      emptyMessage: e.target.value === "",
      messageText: e.target.value,
      messageLength: e.target.value.length,
    })
  }

  handleGoClick() {
    const failPost = (err) => {
      console.error(err)
      this.setState({
        loading: false,
        error: true,
      })
    }

    this.setState({loading: true})
    this.refs.canvas.toBlob( blob => {
      const file = new File([blob], 'image.jpeg', {
          type: 'image/jpeg',
          lastModified: Date.now()
      })

      fetch(this.props.putUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/*'
        },
        body: file
      })
      .then(response => {
        console.log('uploaded')

        fetch('/api/?t=' + this.props.token, {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({message: this.state.messageText})
        })
        .then(response => {
          if (!response.ok) {
            failPost('fetch failed')
          } else {
            console.log(response)
            this.setState({
              uploaded: true,
              loading: false
            })
          }
        })
      })
      .catch(err => {
        failPost(err)
      })
    }, 'image/jpeg', 0.7)
  }

  getRemainingCharactersMessage() {
    const charsLeft = 280 - this.state.messageLength
    return charsLeft + " characters left..."
  }

  renderForm() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <br />
            <JumboPanel />
            <Alert variant="info">
              <b>Got it? Good! Let's post something!</b>
            </Alert>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            { this.props.ninjaName &&
              <h2>You have {this.props.ninjaName}!</h2>
            }

            { this.props.isSecret &&
              <Alert variant="dark"><b>This a secret ninja! It isn't listed on the main page, but you have it. Lucky you!</b></Alert>
            }

            { this.props.ninjaMessage &&
              <div>{this.props.ninjaMessage}</div>
            }

            { this.props.goals &&
              <div>
                <br />
                <h3>This ninja's mission:</h3>
                <ListGroup className='goalsList'>
                  {this.props.goals.map(goal => <ListGroup.Item key={goal}>{goal}</ListGroup.Item>)}
                </ListGroup>
              </div>
            }

            <br />

            {
              this.state.fileAdded && (
                <>
                  <canvas className="preview" ref="canvas" />
                  <br />
                </>
              )
              || (
                <Alert variant="info">
                  <b>Not sure what to post? Post something relevant to the
                  ninja's mission, or post a picture of yourself with the
                  previous person in the chain.</b>
                </Alert>
              )
            }

            <input
              className="inputFile"
              onChange={() => this.handleFileChange()}
              type="file"
              id="uploadedImage"
              name="uploadedImage"
              accept="image/*;capture=camera"
            />
            <label htmlFor="uploadedImage" className="btn btn-primary">
              { (this.state.fileAdded && "change picture") || "select a picture" }
            </label>

            <div className={ this.state.messageLength <= 280 ? "moreCharsLeft" : "noMoreCharsLeft" }>
              { this.getRemainingCharactersMessage() }
            </div>

            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Message</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl as="textarea" onChange={this.handleMessageChange} id="message" aria-label="With textarea" />
            </InputGroup>

            { (this.state.fileAdded && this.state.messageLength <= 280)&& 
              <div>
                <br />
                <Alert variant="info">
                  Clicking the post button will post this content to the
                  ninja's <a href={"https://twitter.com/" + this.props.twitterName}>
                    public twitter feed
                  </a>.
                </Alert>
                <Button onClick={() => this.handleGoClick()}>post!</Button>
              </div>
            }
            <br />
          </Col>
        </Row>
      </Container>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App-center">
          <Star size="200pt" fill="black" className="App-logo" />
        </div>
      )
    } else if (this.state.uploaded) {
      return (
        <div className="App-center">
          <a href={"https://twitter.com/" + this.props.twitterName}>
            <Star size="200pt" className="starClick" fill="green"/>
            <br />
            <h1>Go!</h1>
          </a>
        </div>
      )
    } else {
      return this.renderForm()
    }
  }
}

export default Post
