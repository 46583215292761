import React, { Component } from 'react'
import { Container, Row, Col, Jumbotron } from 'react-bootstrap'

class Challenge extends Component {

  renderChallenge(challenge) {
    console.log(challenge)

    let mask = 128
    const tops = []
    const bots = []
    for (let i = 0; i < 8; i++) {
      bots.push(<div key={i} className="dip-label">{i + 1}</div>)
      if ((mask >> i) & challenge) {
        tops.push(<div key={i} className="dip-switch-on" />)
      } else {
        tops.push(<div key={i} className="dip-switch-off" />)
      }
    }
    return (
      <div className="dip">
        <div>
          {tops}
        </div>
        <div>
          {bots}
        </div>
      </div>
    )
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col>
          <Jumbotron>
            <div className="App-center">
              <h2>challenge:</h2>
              {this.renderChallenge(this.props.challenge)}
              <br />
              challenges remaining: {this.props.challengesRemaining}
            </div>
            { this.props.firstChallenge && 
              <>
                <h1>Oops!</h1>
                <p>
                  This ninja has become out of sync. This happens when too many
                  codes are generated without scanning any of them. This also happens
                  if a challenge is failed.
                </p>
                <p>
                  You must complete several challenges to re-sync the ninja. A
                  challenge consists of entering the indicated sequence on the DIP
                  switch on the left side of the ninja, then pressing the button
                  and scanning the code.
                </p>
              </>
            }
          </Jumbotron>
        </Col></Row>
      </Container>
    )
  }
}

export default Challenge
