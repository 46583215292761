import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const PanelContainer = props => {
  return <Container>{props.children}</Container>
}

const Panel = props => {
  return (
   <Row className="justify-content-md-center">
     <Col>{props.children}</Col>
   </Row>
  )
}


export { PanelContainer, Panel }
