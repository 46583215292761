import React from 'react'
import { Panel } from './Panel'
import { Link } from "react-router-dom";
import { Button, Jumbotron } from 'react-bootstrap'

const JumboPanel = () => {
  return (
    <Panel>
      <Jumbotron>
        <h1>Pass the Ninja!</h1>
        <p>
          Pass the Ninja is a social experiment based on the idea of <a
          href="https://en.wikipedia.org/wiki/Six_degrees_of_separation">six
          degrees of separation</a>. Pass the ninja from person to person until
          it accomplishes its mission! Make sure to document its progress by scanning
          the code and posting.
        </p>
        <h4>The rules are simple:</h4>
        <ol>
          <li>While you have the Ninja, use the code to make Ninja posts</li>
          <li>Keep posting even if the Ninja hasn't met its goal</li>
          <li>Don't keep the Ninja forever, pass it on</li>
          <li>Pass it closer to its goal</li>
          <li>Pass it to somone who will keep the rules</li>
          <li>Watch for future posts to follow the progress of the Ninja after passing it on</li>
        </ol>
        <Link to="/how"><Button>Show me!</Button></Link>
      </Jumbotron>
    </Panel>
  ) 
}

export default JumboPanel
