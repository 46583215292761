import React from 'react'
import { PanelContainer, Panel } from './Panel'
import MaterialIcon from 'material-icons-react'

const How = props => {
  return (
    <PanelContainer>
      <Panel>
        <br />
        <h3>Always pass the ninja!</h3>
        <p>
          The ninja must be passed on from one person to another, taking advantage of
          the <a href="https://en.wikipedia.org/wiki/Six_degrees_of_separation">six
          degress of separation</a>.
        </p>
        <h4>1. Someone passes the ninja to you </h4>
        <p>
          Someone believes that you can help the ninja accomplish its mission
          and makes you a part of the chain.
        </p>
        <div className="App-center App-vpad">
          <MaterialIcon color="#5a0000" size="large" icon="person" />
          <MaterialIcon color="#5a0000" size="large" icon="arrow_right_alt" />
          <MaterialIcon color="#5a0000" size="large" icon="person" />
        </div>
      </Panel>
      <Panel>
        <h4>2. Press and hold the front of the ninja to generate a QR code</h4>
        <p>
          Scanning the code will enable you to post to the ninjas feed. Most
          modern phones support scanning QR codes directly from the camera app.
          If this doesn't, work you can download a 3rd party QR scanner app.
        </p>
        <div className="App-center App-vpad">
          <MaterialIcon color="#5a0000" size="large" icon="camera_alt" />
          <MaterialIcon color="#5a0000" size="large" icon="center_focus_strong" />
        </div>
      </Panel>
      <Panel>
        <h4>3. Document your part of the chain by scanning and posting</h4>
        <p>
          Each ninja has a specific mission. Post pictures and messages related
          to its mission, or where it has been.
        </p>
        <div className="App-center App-vpad">
          <MaterialIcon color="#5a0000" size="large" icon="add_a_photo" />
          <MaterialIcon color="#5a0000" size="large" icon="edit" />
        </div>
      </Panel>
      <Panel>
        <h4>4. You decide who gets the ninja next</h4>
        <p>
          Try to think of someone who is closer to the ninja's goals than you
          are. Make sure to explain the process to them. Let them know that
          they must eventually pass the ninja on to someone else.
        </p>
        <div className="App-center App-vpad">
          <MaterialIcon color="#5a0000" size="large" icon="person" />
          <MaterialIcon color="#5a0000" size="large" icon="arrow_right_alt" />
          <MaterialIcon color="#5a0000" size="large" icon="person" />
        </div>
      </Panel>
      <Panel>
        <h4>5. The ninja gets passed on and on until it reaches its goal!</h4>
        <p>
          If each member of the chain passes the ninja closer, it
          will eventually be able to complete its mission!
        </p>
        <div className="App-center App-vpad">
          <MaterialIcon color="#5a0000" size="medium" icon="person" />
          <MaterialIcon color="#5a0000" size="medium" icon="arrow_right_alt" />
          <MaterialIcon color="#5a0000" size="medium" icon="person" />
          <MaterialIcon color="#5a0000" size="medium" icon="arrow_right_alt" />
          <MaterialIcon color="#5a0000" size="medium" icon="person" />
          <MaterialIcon color="#5a0000" size="medium" icon="arrow_right_alt" />
          <MaterialIcon color="green" size="medium" icon="person" />
          <MaterialIcon color="green" size="medium" icon="sentiment_very_satisfied" />
        </div>
      </Panel>
    </PanelContainer>
  )
}

export default How
