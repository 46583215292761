import React from 'react'

const SVG = ({
    className="",
    fill="#FFFFFF",
    size="128pt",
  }) =>

<svg className={className} width={size} height={size} preserveAspectRatio="xMidYMid meet" viewBox="-35 -40 70 80" xmlns="http://www.w3.org/2000/svg" version="1.1">
  <title>OpenSCAD Model</title>
  <path fill={fill} d="
    M -34.641,20 L -15.931,18.1087 L -16,17.3205 L -15.9088,16.2786 L -15.6382,15.2684 L -15.1962,14.3205
    L -14.5963,13.4638 L -13.8567,12.7242 L -13,12.1244 L -12.0521,11.6824 L -11.0419,11.4117 L -10,11.3205
    L -8.95811,11.4117 L -7.94788,11.6824 L -7,12.1244 L -6.14327,12.7242 L -5.40373,13.4638 L -4.80385,14.3205
    L -4.36184,15.2684 L -4.09115,16.2786 L -4,17.3205 L -4.09115,18.3624 L -4.36184,19.3726 L -4.80385,20.3205
    L -5.40373,21.1772 L -6.14327,21.9168 L -7,22.5167 L -7.71704,22.851 L 0,40 L 7.71704,22.851
    L 7,22.5167 L 6.14327,21.9168 L 5.40373,21.1772 L 4.80385,20.3205 L 4.36184,19.3726 L 4.09115,18.3624
    L 4,17.3205 L 4.09115,16.2786 L 4.36184,15.2684 L 4.80385,14.3205 L 5.40373,13.4638 L 6.14327,12.7242
    L 7,12.1244 L 7.94788,11.6824 L 8.95811,11.4117 L 10,11.3205 L 11.0419,11.4117 L 12.0521,11.6824
    L 13,12.1244 L 13.8567,12.7242 L 14.5963,13.4638 L 15.1962,14.3205 L 15.6382,15.2684 L 15.9088,16.2786
    L 16,17.3205 L 15.931,18.1087 L 34.641,20 L 23.6481,4.74236 L 23,5.19615 L 22.0521,5.63815
    L 21.0419,5.90885 L 20,6 L 18.9581,5.90885 L 17.9479,5.63815 L 17,5.19615 L 16.1433,4.59627
    L 15.4037,3.85672 L 14.8038,3 L 14.3618,2.05212 L 14.0912,1.04189 L 14,-0 L 14.0912,-1.04189
    L 14.3618,-2.05212 L 14.8038,-3 L 15.4037,-3.85672 L 16.1433,-4.59627 L 17,-5.19615 L 17.9479,-5.63815
    L 18.9581,-5.90885 L 20,-6 L 21.0419,-5.90885 L 22.0521,-5.63815 L 23,-5.19615 L 23.6481,-4.74236
    L 34.641,-20 L 15.931,-18.1087 L 16,-17.3205 L 15.9088,-16.2786 L 15.6382,-15.2684 L 15.1962,-14.3205
    L 14.5963,-13.4638 L 13.8567,-12.7242 L 13,-12.1244 L 12.0521,-11.6824 L 11.0419,-11.4117 L 10,-11.3205
    L 8.95811,-11.4117 L 7.94788,-11.6824 L 7,-12.1244 L 6.14327,-12.7242 L 5.40373,-13.4638 L 4.80385,-14.3205
    L 4.36184,-15.2684 L 4.09115,-16.2786 L 4,-17.3205 L 4.09115,-18.3624 L 4.36184,-19.3726 L 4.80385,-20.3205
    L 5.40373,-21.1772 L 6.14327,-21.9168 L 7,-22.5167 L 7.71704,-22.851 L 0,-40 L -7.71704,-22.851
    L -7,-22.5167 L -6.14327,-21.9168 L -5.40373,-21.1772 L -4.80385,-20.3205 L -4.36184,-19.3726 L -4.09115,-18.3624
    L -4,-17.3205 L -4.09115,-16.2786 L -4.36184,-15.2684 L -4.80385,-14.3205 L -5.40373,-13.4638 L -6.14327,-12.7242
    L -7,-12.1244 L -7.94788,-11.6824 L -8.95811,-11.4117 L -10,-11.3205 L -11.0419,-11.4117 L -12.0521,-11.6824
    L -13,-12.1244 L -13.8567,-12.7242 L -14.5963,-13.4638 L -15.1962,-14.3205 L -15.6382,-15.2684 L -15.9088,-16.2786
    L -16,-17.3205 L -15.931,-18.1087 L -34.641,-20 L -23.6481,-4.74236 L -23,-5.19615 L -22.0521,-5.63815
    L -21.0419,-5.90885 L -20,-6 L -18.9581,-5.90885 L -17.9479,-5.63815 L -17,-5.19615 L -16.1433,-4.59627
    L -15.4037,-3.85672 L -14.8038,-3 L -14.3618,-2.05212 L -14.0912,-1.04189 L -14,-0 L -14.0912,1.04189
    L -14.3618,2.05212 L -14.8038,3 L -15.4037,3.85672 L -16.1433,4.59627 L -17,5.19615 L -17.9479,5.63815
    L -18.9581,5.90885 L -20,6 L -21.0419,5.90885 L -22.0521,5.63815 L -23,5.19615 L -23.6481,4.74236
    z
    M -4,-0 L -3.93923,-0.694592 L -3.75877,-1.36808 L -3.4641,-2 L -3.06418,-2.57115 L -2.57115,-3.06418
    L -2,-3.4641 L -1.36808,-3.75877 L -0.694592,-3.93923 L 0,-4 L 0.694592,-3.93923 L 1.36808,-3.75877
    L 2,-3.4641 L 2.57115,-3.06418 L 3.06418,-2.57115 L 3.4641,-2 L 3.75877,-1.36808 L 3.93923,-0.694592
    L 4,-0 L 3.93923,0.694592 L 3.75877,1.36808 L 3.4641,2 L 3.06418,2.57115 L 2.57115,3.06418
    L 2,3.4641 L 1.36808,3.75877 L 0.694592,3.93923 L 0,4 L -0.694592,3.93923 L -1.36808,3.75877
    L -2,3.4641 L -2.57115,3.06418 L -3.06418,2.57115 L -3.4641,2 L -3.75877,1.36808 L -3.93923,0.694592
    z"
  />
</svg>

export default SVG
