import React, { Component } from 'react'
import Star from './StarIcon'
import Post from './Post'
import Challenge from './Challenge'

class Token extends Component {
  constructor(props) {
    super(props)
    this.state = {
      response: null,
      error: false,
    }
  }

  getToken() {
    return this.props.match.url.substr(3)
  }

  componentDidMount() {
    fetch('/api/?t=' + this.getToken())
    .then(response => response.json())
    .then(response => {
      console.log(response)
      this.setState({
        response: response,
      })
    })
    .catch( err => {
      this.setState({
        error: true,
      })
    })
  }

  renderError() {
    return (
      <div className="App-center">
        <Star size="200pt" fill="red" className="starClick" />
      </div>
    )
  }

  renderLoading() {
    return (
      <div className="App-center">
        <Star size="200pt" fill="black" className="App-logo" />
      </div>
    )
  }

  render() {
    const response = this.state.response

    // explicit error
    if (this.state.error) {
      return this.renderError()
    }
    // loading
    else if (response == null) {
      return this.renderLoading()
    }
    // token valid
    else if (response.success) {
      return <Post
        putUrl={response.putUrl}
        ninjaName={response.info.name}
        ninjaMessage={response.info.message}
        goals={response.info.goals}
        twitterName={response.info.twitterName}
        isSecret={response.info.isSecret}
        token={this.getToken()}
      />
    }
    // challenge
    else {
      return <Challenge firstChallenge={!response.syncSuccess} challenge={response.challenge} challengesRemaining={response.challengesRemaining}/>
    }
  }
}

export default Token
