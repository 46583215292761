import React from 'react'
import './App.css'
import './BootstrapOverride.css'
import Star from './StarIcon'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Token from './Token'
import How from './How'
import JumboPanel from './JumboPanel'
import { PanelContainer, Panel } from './Panel'
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap'

const Help = () => {
  return (
    <Container>
      <br />
      <Row className="justify-content-md-center">
        <Col lg="8">
          <h3>The screen is blank, or no code is displayed, when the button is pressed</h3>
          <p>
            Likely the batteries need to be replaced. Remove the bottom of the
            Ninja, replace the 3 AAA batteries, replace the bottom, and try
            again.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col lg="8">
          <h3>Out-of-sync message when code is scanned</h3>
          <p>
            Generating too many codes without scanning any of them will de-sync
            the ninja. Follow the directions that are given by entering the
            correct sequence on the DIP switch on the left of the device and
            scanning the code. You will need to repeat this process several
            times before the device is re-synced. Once re-synced the device
            will operate as normal.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

const About = () => {
  return (
    <div>

      <div className="App-jumbotron">
        <br />
        <PanelContainer>
          <JumboPanel />
        </PanelContainer>
      </div>

      <br />
      <PanelContainer>
        <Panel>
          <h3>The Ninjas</h3>
          <p>
            There are 5 ninjas. Each has a different mission.
          </p>
          <div>
            <Star fill="blue" size="18pt"/>
            the Blue Ninja (coming soon...)
          </div>
          <div>
            <Star fill="red" size="18pt"/>
            the Red Ninja (coming soon...)
          </div>
          <div>
            <Star fill="purple" size="18pt"/>
            the Purple Ninja (coming soon...)
          </div>
          <div>
            <Star fill="orange" size="18pt"/>
            the Orange Ninja (coming soon...)
          </div>
          <div>
            <Star fill="#eded00" size="18pt"/>
            the Yellow Ninja (coming soon...)
          </div>
          <div>
            <Star fill="#000000" size="18pt"/>
            ???
          </div>
          <br />
        </Panel>
      </PanelContainer>
    </div>
  )
}

const NinjaNav = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/">
        <Star fill="#ced0d2" size="32pt"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">About</Nav.Link>
          <Nav.Link href="/how">How it works</Nav.Link>
          <Nav.Link href="/help">Help</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const Footer = () => {
   return (
     <div className="App-footer">
       <Container><Row><Col>
         Need help? <a href="mailto:support@passthe.ninja">support@passthe.ninja</a>
         <br />
         PassThe.Ninja is an experimental project, comments and feedback are appreciated
       </Col></Row></Container>
     </div>
   )
}

const AppRouter = () => {
  return (
    <div className="App-container">
      <NinjaNav />

      <div className="App-body">
        <Router>
          <Route path="/" exact component={About} />
          <Route path="/how" exact component={How} />
          <Route path="/help" exact component={Help} />
          <Route path="/t/*" component={Token} />
        </Router>
      </div>

      <Footer />
    </div>
  )
}

export default AppRouter
